

import KirbyLink from './kirbylink.vue'
export default {
    name: "headlinectas",
    props: {
        content: Object
    },
    components: {
        KirbyLink
    }
}

